import React, { useEffect, useState } from "react";
import FrontHeader from "../../../shared/FrontHeader/FrontHeader";
import BreadCrumb from "../../../shared/BreadCrumb";
import { useStepType } from "../../../../utils/type";
import { useStep } from "react-hooks-helper";
import PanierValidStep from "./steps/PanierValidStep";
import AdresseLivraison from "./steps/AdresseLivraison";
import ModePaiement from "./steps/ModePaiement";
import UseAddOrUpdateOrderForm from "./requestForm/UseAddOrUpdateOrderForm";
import { useAppSelector } from "../../../../redux/hook";
import { useNavigate } from "react-router-dom";
import { Color } from "../../../../utils/theme";
import Swal from "sweetalert2";
import ConnexionModal from "../../../shared/FrontHeader/modal/ConnexionModal";
import { useGetVendeurByApiKeyQuery } from "../../../../utils/api/product/product.api";
import { VendeurApikey } from "../../../../utils/http";
import { formatCurrency} from "../../../../utils/Utils";


const steps = [
  { id: "panier-validation", Component: PanierValidStep },
  { id: "address-livraison", Component: AdresseLivraison },
  { id: "mode-paiement", Component: ModePaiement },
];
const tabs = ["1", "2", "3"];

function ValidateCommand() {
  const { user } = useAppSelector((s) => s?.user);
  const { data: vendeur } = useGetVendeurByApiKeyQuery(VendeurApikey);
  const [modalConnexion, setModalConnexion] = useState(false);
  const { cartItems, subAmount, tax, totalAmount } = useAppSelector(
    (state) => state.cart
  );

  const {
    step,
    navigation: { go, next, previous },
    index,
  }: useStepType = useStep({ initialStep: 0, steps });
  const { Component } = step;

  const {
    register,
    onSubmit,
    isLoading,
    errors,
    handleChangePhone,
    phone,
    onChangeAdresse,
    adresse,
    setValue,
  } = UseAddOrUpdateOrderForm(next,previous);

  useEffect(() => {
    if (user?.id) {
      setValue("telephone", user?.telephone);
      setValue("adresse", user?.adresse);
      setValue("user", user?.id);
      setValue("step", 1);
    }
  }, [user]);

  useEffect(() => {
    let code_commande: number | string = new Date().getTime();
    setValue("code_commande", code_commande.toString());
  }, []);

  useEffect(() => {
    let produitVisiteur = cartItems?.map((x) => ({
      produit: x?.produit?.id || x?.produit,
      prix: x?.prix,
      quantite: x?.quantite,
      variations: x?.variations?.map((x) => x.id),
    }));

    setValue("vendeur", cartItems[0]?.vendeur);
    setValue("produits", produitVisiteur);
    setValue("montant_total", totalAmount);
  }, [cartItems]);

  const precedent = () => {
    setValue("step", 1);
    setValue("mode_de_paiement", '');
    previous();
  };

  const ifUserConnected = () => {
    Swal.fire({
      icon: "success",
      title: `Connectez vous`,
      text: "Veuillez vous connecter pour finaliser votre achat .",
      iconColor: Color.success,
      showConfirmButton: false,
      timer: 3000,
    }).then(() => {
      setModalConnexion(true);
    });
  };

  return (
    <div className="landing-page-component">
      <FrontHeader />
      <div className="page-content pb-5 pb-5">
        <div className="container pt-4">
          {index === 0 && (
            <BreadCrumb pageName="mon panier" secondPage="valider mon panier" />
          )}
          {index === 1 && (
            <BreadCrumb pageName="mon panier" secondPage="Livraison" />
          )}
          {index === 2 && (
            <BreadCrumb pageName="mon panier" secondPage="Finaliser" />
          )}
          <div className="validate-command-content pt-5 pb-5">
            <div className="row row-validate">
              <div className="col-lg-9 validate-wrapper">
                <form onSubmit={onSubmit}>
                  <div className="tabs-wrapper pb-4">
                    <ul className="tabs-container">
                      {tabs.map((tab, key) => (
                        <li
                          className={`
                                                    tab-item it${key}
                                                    ${
                                                      index === key
                                                        ? "tab-item-active"
                                                        : ""
                                                    }
                                                    ${
                                                      index === 2
                                                        ? "eas_bg"
                                                        : ""
                                                    }
                                                `}
                          //onClick={() => go(key)}
                        >
                          {tab}
                          {key === 0 && (
                            <span className="tab-indicator">Panier</span>
                          )}
                          {key === 1 && (
                            <span className="tab-indicator">Livraison</span>
                          )}
                          {key === 2 && (
                            <span className="tab-indicator">Finaliser</span>
                          )}
                        </li>
                      ))}
                      <div className="progress validate-progress">
                        {index === 0 && (
                          <div
                            className="progress-bar custom-progress"
                            role="progressbar"
                            aria-label="Basic example"
                            style={{
                              width: "0%",
                            }}
                            aria-valuenow={25}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          ></div>
                        )}
                        {index === 1 && (
                          <div
                            className="progress-bar custom-progress"
                            role="progressbar"
                            aria-label="Basic example"
                            style={{
                              width: "53%",
                            }}
                            aria-valuenow={75}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          ></div>
                        )}
                        {index === 2 && (
                          <div
                            className="progress-bar custom-progress"
                            role="progressbar"
                            aria-label="Basic example"
                            style={{
                              width: "100%",
                            }}
                            aria-valuenow={100}
                            aria-valuemin={0}
                            aria-valuemax={100}
                          ></div>
                        )}
                      </div>
                    </ul>
                  </div>
                  <Component
                    register={register}
                    errors={errors}
                    handleChangePhone={handleChangePhone}
                    phone={phone}
                    vendeur={vendeur}
                    onChangeAdresse={onChangeAdresse}
                    adresse={adresse}
                  />
                  <div className="action-btn-content d-flex justify-content-end align-items-center gap-3 pt-4">
                    {index >= 1 && cartItems.length > 0 && (
                      <button
                        className="step-action-btn validate-go-back-btn"
                        // disabled={index === 2}
                        type="button"
                        onClick={precedent}
                      >
                        Précédent
                      </button>
                    )}
                    {index === 0 && cartItems.length > 0 && (
                      <button
                        className="step-action-btn parcours-submit-btn"
                        type="button"
                        onClick={() => next()
                          //user?.slug ? next() : ifUserConnected()
                        }
                      >
                        Suivant
                      </button>
                    )}
                    {index === 1 && cartItems.length > 0 && (
                      <button
                        className="step-action-btn parcours-submit-btn"
                        type="submit"
                        // onClick={next}
                      >
                        Suivant
                      </button>
                    )}
                    {index === 2 && cartItems.length > 0 && (
                      <button
                        className="step-action-btn parcours-submit-btn "
                        type="submit"
                        disabled={isLoading}
                      >
                        {isLoading ? "Validation ..." : "Valider ma commande"}
                      </button>
                    )}
                  </div>
                </form>
              </div>

              <div className="col-lg-3">
                <div className="cart-card">
                  <h1 className="title pb-4">Total du panier</h1>
                  <div className="separator"></div>
                  <div className="total d-flex align-items-center justify-content-between pt-3">
                    <span className="sm-title">TOTAL</span>
                    <span className="sm-value total">{formatCurrency(totalAmount,vendeur?.devise)}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ConnexionModal
        modalConnexion={modalConnexion}
        setModalConnexion={setModalConnexion}
      />
    </div>
  );
}

export default ValidateCommand;
