import React, { useState, useEffect } from "react";
import FrontFooter from "../shared/FrontFooter/FrontFooter";
import FrontHeader from "../shared/FrontHeader/FrontHeader";
import FilterItems from "../user/ParcoursAchat/FilterItems";
import { ProductItem } from "../HomePage/HomePage";
import { useSearchParams, useLocation } from "react-router-dom";
import {
  useGetProductListQuery,
} from "../../utils/api/product/product.api";
import { usePagination } from "../../utils/hooks";
import Pagination from "../common/Pagination";
import { FaFilter } from "react-icons/fa";
import FilterMobileItem from "../user/ParcoursAchat/modal/FilterMobileItem";
import { ProductItemsSkeleton } from "../user/ParcoursAchat/ParcoursAchat";
import { Col, Row } from "react-bootstrap";

let options = [
  {
    label: "Nombre d'avis",
    value: "avreageRate",
  },
  {
    label: "Date de création",
    value: "createdAt",
  },
  {
    label: "Nom",
    value: "name",
  },
  {
    label: "Note moyenne",
    value: "rateCount",
  },
  {
    label: "Prix",
    value: "price",
  },
];

function OurProduct() {
  const { page, perPage, limit, setPage, onChange } = usePagination(12);
  let { state } = useLocation();
  let [searchParams, setSearchParams] = useSearchParams();
  const [show, setShow] = useState(false);

  const handleShow = () => setShow(true);
  const [query, setQuery] = React.useState({
    categorie: searchParams?.get("categorie") || "",
    sous_categorie: searchParams?.get("sous_categorie") || "",
    prix_min: searchParams?.get("min") || "",
    prix_max: searchParams?.get("max") || "",
    orderBy: searchParams?.get("orderBy") || "",
  });
  const { data = { results: [], nbPage: 0 }, isFetching } =
    useGetProductListQuery({
      productType: "product",
      page,
      limit,
      prix_min: searchParams?.get("prix_min") || "",
      prix_max: searchParams?.get("prix_max") || "",
      categorie: searchParams?.get("categorie") || "",
      // order: "ASC",
      sous_categorie: searchParams?.get("sous_categorie")
        ? JSON.parse(searchParams?.get("sous_categorie") || "[]")
        : "",
    });

  React.useEffect(() => {
    window.scrollTo({
      top: 100,
      behavior: "smooth",
    });
  }, [page, query]);

  return (
    <>
      <div className="landing-page-component">
        <FrontHeader />
        <div className="page-content">
          <div className="container-easy-market">
            <div className="row row-achat-page mb-5">
              <FilterItems
                searchParams={searchParams}
                setSearchParams={setSearchParams}
                query={query}
                setQuery={setQuery}
                setPage={setPage}
              />
              <div className="container-filter-parcours-achat-mobile no-view-desktop">
                <button
                  className="btn btn-filter-mobile"
                  type="button"
                  onClick={() => handleShow()}
                >
                  <FaFilter />
                  <span className="ps-2">Filtrer</span>
                </button>
              </div>

              <div className="col-lg-9 col-md-7 col-right-achat-page">
                <div className="content-col-right-achat-page">
                  <div
                    className="container-home-page mb-3 our-product-container-homepage"
                    style={{ minHeight: "51rem" }}
                  >
                    <Row className="mt-3">
                      {!!isFetching && <ProductItemsSkeleton />}
                      {!isFetching &&
                        !!data?.results?.length &&
                        data?.results?.map((product, key) => {
                          return (
                            <Col
                              sm={6}
                              xs={6}
                              md={4}
                              lg={4}
                              className="mb-3 col-product-item d-flex col-content-product-home"
                              key={key}
                              style={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              <div className="product-item-carousel">
                                <ProductItem item={product} key={key} />
                              </div>
                            </Col>
                          );
                        })}
                      {!isFetching && data?.results?.length == 0 && (
                        <div className="col-sm-12 col-md-12 mb-3 m-t-36 d-flex justify-content-center">
                          <p>Aucun produit disponible </p>
                        </div>
                      )}
                    </Row>

                    <div className="d-flex justify-content-center">
                      <Pagination
                        page={page}
                        perPage={limit}
                        total={data?.count}
                        onPageChange={(page: number) => onChange(page, perPage)}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* <FooterPage /> */}
          </div>
        </div>
        <FrontFooter />
        <FilterMobileItem
          show={show}
          setShow={setShow}
          searchParams={searchParams}
          setSearchParams={setSearchParams}
          query={query}
          setQuery={setQuery}
          setPage={setPage}
        />
      </div>
    </>
  );
}

export default OurProduct;
