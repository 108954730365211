import photo1 from "../../../assets/images_flo/appImages/founder_1.png";

export const TeamData = [
    {
        id: 1,
        photo: photo1,
        name: "Aminata DIALLO",
        role: "Fondatrice",
        twitter: "https://twitter.com",
        facebook: "https://facebook.com",
        linkedin: "https://linkedin.com"   
    },

];


