import React from "react";
import { AlertInfo } from "../../../../common/Alert";
import BootstrapTable from "react-bootstrap-table-next";
import TableSkeleton from "../../../../common/TableSkeleton";
import { getImage } from "../../../../../utils/Utils";
import { useGetListProductQuery } from "../../../../../utils/api/product/product.api";
import Empty from "../../../../../assets/images/appImages/category.png";

function CommandeDetailTable({ articles, isLoading }) {
  const { data } = useGetListProductQuery();

  const productFormatter = (cell: any, row: any) => {
    let produit = data?.find((x) => x.id == row?.produit?.id);

    const imgProd =
      produit?.type == "taille_unique"
        ? produit?.images[0]?.image
        : row?.variations[0]?.images[0]
        ? row?.variations[0]?.images[0]?.image
        : produit?.variations[0]?.images[0]
        ? produit?.variations[0]?.images[0]?.image
        : Empty;

    return (
      <div className="d-flex align-items-center gap-2">
        <div className="product-image-container">
          <img
            loading="lazy"
            src={imgProd ? getImage(imgProd) : Empty}
            alt="image"
            className="cart-image"
          />
        </div>
        <div>
          <p className="product-name-panier mb-1">{produit?.nom}</p>
          <p className="product-category-name mb-0">
            {" "}
            {produit?.categorie?.nom}
          </p>
          <p className="product-category-name mb-0">
            {row?.variations[0] && row?.variations[0]?.taille}
          </p>
        </div>
      </div>
    );
  };

  const totalFormatter = (cell: any, row: any) => {
    return row?.quantite * row?.prix;
  };

  const columns = [
    {
      dataField: "nom",
      text: "Produits",
      formatter: productFormatter,
    },
    {
      dataField: "quantite",
      text: "Quantité",
    },
    {
      dataField: "prix",
      text: "Prix",
    },
    {
      dataField: "total",
      text: "Total",
      formatter: totalFormatter,
    },
  ];

  return (
    <>
      {isLoading && <TableSkeleton headers={columns} />}
      {!isLoading && (
        <BootstrapTable
          keyField="id"
          data={articles || []}
          columns={columns}
          condensed
          hover
          wrapperClasses="table-responsive commande-detail-table"
          noDataIndication={() => <AlertInfo message="Aucune donnée trouvée" />}
        />
      )}
    </>
  );
}

export default CommandeDetailTable;
