export const Color = {
  jaune: "#FCAE3B",
  bleu: "#28348a",
  vert: "#1AA981",
  themeColor: "#F9C1CC",
  rouge: "#E73628",
  success: "#1AA981",
  default: "#2a71d5",
  danger: "#E73628",
};

export const Font = {
  family: "#Montserrat",
};